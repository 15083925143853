import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { serialize } from 'object-to-formdata';
import axios from '../../utils/axios';
import { createDynamicURL } from '../../utils/dynamicParams';
// ----------------------------------------------------------------------
const initialState = {
    status: 'idle',
    model: null,
    models: [],
    meta: {
        currentPage: 0,
        nextPage: false,
        prevPage: false,
        totalPages: 0,
        totalRecords: 0,
    },
};
// ----------------------------------------------------------------------
export const getModels = createAsyncThunk('model/getModels', async ({ page = 1, rowsPerPage = 10, orderBy = 'createdAt', order = 'desc', isPaging = true, filterName = '', filterActive = '', brandId = '', }) => {
    let data;
    const queryParams = {
        search: {
            'brand.id': brandId,
            name: filterName,
            active: filterActive !== '' ? Number(filterActive) : filterActive,
        },
        page: Number(page) + 1,
        perPage: rowsPerPage,
        orderBy,
        sortedBy: order,
        isPaging,
    };
    const url = createDynamicURL('/models', queryParams);
    try {
        const response = await axios.get(url);
        data = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const getModel = createAsyncThunk('model/getModel', async (id) => {
    let data;
    try {
        const response = await axios.get(`/models/${id}`);
        data = await response.data;
        if (response.status === 200) {
            return data.data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const createModel = createAsyncThunk('model/createModel', async (Data) => {
    let data;
    try {
        const formData = serialize({
            active: Data.active,
            name: Data.name.toString(),
            code: Data.code.toString(),
            brandId: Data.brandId.toString(),
            defaultImage: Data.defaultImage,
        });
        const response = await axios.post(`/models`, formData);
        data = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const editModel = createAsyncThunk('model/editModel', async ({ id, Data }) => {
    let data;
    try {
        const formData = serialize({
            active: Data.active,
            name: Data.name.toString(),
            code: Data.code.toString(),
            brandId: Data.brandId.toString(),
            defaultImage: Data.defaultImage,
        });
        const response = await axios.put(`/models/${id}`, formData);
        data = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const deleteModel = createAsyncThunk('model/deleteModel', async ({ id, getParams }, thunkAPI) => {
    let data;
    try {
        const response = await axios.delete(`/models/${id}`);
        data = await response.data;
        if (response.status === 200) {
            if (getParams)
                thunkAPI.dispatch(getModels({ ...getParams }));
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const deleteManyModels = createAsyncThunk('model/deleteManyModels', async ({ ids, getParams }, thunkAPI) => {
    let data;
    try {
        const response = await axios.delete(`/models/many`, { data: { ids } });
        data = await response.data;
        if (response.status === 200) {
            if (getParams)
                thunkAPI.dispatch(getModels({ ...getParams }));
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
const slice = createSlice({
    name: 'model',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getModels.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(getModels.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.models = action.payload.data;
            state.meta = action.payload.meta;
        })
            .addCase(getModels.rejected, (state, action) => {
            state.status = 'failed';
        })
            .addCase(getModel.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(getModel.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.model = action.payload;
        })
            .addCase(getModel.rejected, (state, action) => {
            state.status = 'failed';
        })
            .addCase(createModel.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(createModel.fulfilled, (state, action) => {
            state.status = 'succeeded';
        })
            .addCase(createModel.rejected, (state, action) => {
            state.status = 'failed';
        })
            .addCase(editModel.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(editModel.fulfilled, (state, action) => {
            state.status = 'succeeded';
        })
            .addCase(editModel.rejected, (state, action) => {
            state.status = 'failed';
        });
    },
});
// Reducer
export const reducer = slice.reducer;
export default slice;
